import React from 'react'

function LeadServices(){
    return(
        <section className="section">
            <div className="section-lead">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h3 className="lead-title anim-bot mb-4 mb-lg-0">
                                <span className="text-muted">02.</span>
                                <br />
                                Services
                            </h3>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row lead-content">
                                <div className="col-12 col-lg-4 mb-4 mb-lg-2">
                                    <div className="anim-bot">
                                        <h4 className="lead-subtitle">User Experience and Creative Design</h4>
                                        <p className="lead-small text-muted">We design websites and applications that are both functional and energising - providing users with a holistic experience that will make them want to take action.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 mb-4 mb-lg-2">
                                    <div className="anim-bot">
                                        <h4 className="lead-subtitle">Web and Mobile App Development</h4>
                                        <p className="lead-small text-muted">From SEO optimization to animation, our CTO and his team are the ones that make your project a reality via clean, creative front-end and back-end code.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 mb-4 mb-lg-2">
                                    <div className="anim-bot">
                                        <h4 className="lead-subtitle">Ongoing Support and Growth</h4>
                                        <p className="lead-small text-muted">Our client partnerships are designed for the long-term. We work proactively with our clients to continually evolve their presence and grow brand and digital exposure.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LeadServices