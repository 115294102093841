import React from 'react'

function LeadTeam(){
    const date = new Date().getFullYear()
    return(
        <section className="section">
            <div className="img-anim">
                <div className="img-anim-bg"></div>
                <img src={`/assets/images/golden-bridge.webp`} alt='Da Nang Golden Bridge' />
            </div>
            
        </section>
    )
}

export default LeadTeam