import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Hero from '../components/Hero'
import LeadContact from '../components/LeadContact'
import Footer from '../components/Footer'

function Contact(){
    const windowWidth = window.innerWidth
    const {afterLoading, afterLoadingImg, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    useEffect(() => {
        afterLoadingImg()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    console.log(windowWidth)

    return(
        <>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 0.75, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero>
                <div className="row">
                    <div className="col-12">
                        <h1 className="hero-title">
                            Have an idea?
                        </h1>
                        <p className="hero-title">
                            Tell us about it <a className="highlighted-link" href="mailto:bek@thebay.city">bek@thebay.city</a>
                        </p>
                    </div>
                </div>
            </Hero>
            <LeadContact />
            <Footer />
        </>
    )
}

export default Contact