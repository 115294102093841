import React from 'react'
import {Link} from 'react-router-dom'

import MainMenu from '../elements/MainMenu'
function Header(){
    return(
        <>
            <header>
                <div className="container-fluid">
                    <div className="row header-anim">
                        <div className="col-5 site-logo">
                            <Link to='/'>
                                <img className="bayLogo hidden-xs" src={`assets/images/logo.svg`} alt="The Bay City" />
                                <img className="bayLogo xs-only" src={`assets/images/logo-short.svg`} alt="The Bay City" />
                            </Link>
                        </div>
                        <div className="col-7 main-menu">
                            <MainMenu />
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header