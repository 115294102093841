import React from 'react'

function Footer(){

    const date = new Date().getFullYear()

    const socialFooter = [
        {
            label: "LinkedIn",
            url: "https://www.linkedin.com/company/thebaycity"
        },
        {
            label: "Facebook",
            url: "https://www.facebook.com/thebaycitycompany"
        }
    ]

    const displaySocialFooter = socialFooter.map((item, index) => <li key={index} className="mr-md-5 mr-3 ml-3 ml-md-0"><a className="highlighted-link lead-small" href={item.url} target="_blank">{item.label}</a></li>)

    return(
        <section id="sectionFooter" className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 col-md-3 mb-4 text-center text-md-left">
                        <div>
                            <h4 className="lead-subtitle">Business Inquiries</h4>
                            <p className="lead-small">
                                <a className="highlighted-link lead-small" href="mailto:bek@thebay.city">bek@thebay.city</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-4 text-center text-md-left">
                        <div>
                            <h4 className="lead-subtitle">Careers</h4>
                            <p className="lead-small">
                                <a className="highlighted-link" href="mailto:toan@thebay.city">toan@thebay.city</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-4 text-center text-md-left">
                        <h4 className="lead-subtitle">Our Location</h4>
                        <p className="lead-small">Da Nang, Vietnam</p>
                    </div>
                    <div className="col-12 col-md-3 text-center text-md-left">
                        <ul className="d-flex flex-row nav mb-4 justify-content-center justify-content-md-start">
                            {displaySocialFooter}
                        </ul>
                        <p className="lead-small">© {date} The Bay City</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer